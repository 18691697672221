import React, { useState, useEffect } from 'react';
import server from '../../server/server';
import StartView from './StartView';
import FillEmpty from '../fillEmpty/FillEmpty'
import EndView from './EndView'

function UserExam(props) {
    const { match: { params } } = props;
    console.log(params);
    const {examPaperId} = params;


    const [examProcess, setExamProcess] = useState(null);

    useEffect(() => {
     
     
            server.post('userExam/currentStatus',{examPaperId}).then(setExamProcess)
        
    }, []);


  

    if(examProcess == null){
        return <h1>Loading</h1>
    }



    if(examProcess.step == "START"){
        const startExam = ()=>{
            server.post('userExam/startExam',{examPaperId}).then(setExamProcess);
        };


        return <StartView {...examProcess} startExam={startExam} />

    }else if(examProcess.step == "END"){
        return <EndView {...examProcess} />
    }else if (examProcess.step == 'ERROR' || examProcess.step == 'EXAM'){
        const submitAnswer = (answer)=>{
            server.post('userExam/submitAnswer',{examPaperId,answer}).then(setExamProcess);
        };

        const nextQuestion = () =>{
            server.post('userExam/nextQuestion',{examPaperId}).then(setExamProcess);
        }

        let ExamView = null;

        if(examProcess.question.questionType == 'fill_empty'){
            ExamView = <FillEmpty {...examProcess} submitAnswer={submitAnswer} nextQuestion={nextQuestion} />
        };

        return (<>
       
            <div className="text-center pt-4 ">{examProcess.title}({examProcess.index+1}/{examProcess.total})</div>
            {ExamView}
            <div className="text-center py-3 h5">
                <div>
                    正确: <span className="text-success mr-4">{examProcess.right}</span>
                    错误: <span className="text-danger">{examProcess.wrong}</span>

                </div>
         
             </div>


        </>);


    }


    return (
        <div>
            {params.examPaperId}
        </div>
    )
}

export default UserExam
