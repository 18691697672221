import React from 'react'
import { Link } from 'react-router-dom'
function EndView(props) {

    const {right,wrong,total,title} = props;

    return (<div className="row  h-100 pt-5  justify-content-center">
    <div className="col-10  p-5">
        <div className="text-center  h4">恭喜你!你完成了{title}</div>
        <div className="text-center pt-3">正确率:</div>
        <div className="text-center pb-3 display-2">{ Math.floor(right*100/total)} %</div>
        <div className="text-center "><Link to="/" className="btn btn-primary w-50 p-3">返回作业列表</Link> </div>
    </div>

</div> )
}

export default EndView
