
import axios from 'axios';


class ServerApi{





    constructor(oldToken,dispatch){
        this.token = oldToken;
        this.dispatch = dispatch;
        console.log(window.location.hostname);
        if(window.location.hostname == "localhost"){
            this.baseUrl = "http://127.0.0.1:8080/api/v1/";
        }else{
            this.baseUrl = "/api/v1/";
        }
        
      
        
    
    }

    async ajax(options){
        if(this.token){
            options.headers = { 
                'Token' : this.token,
             }
        }  
        return axios(options).then(result=>
            result.data
            ).catch(e =>{
            console.log(e);
        })
    }
    async get(apiName){
        const options = {
            method: 'GET',     
            url: this.baseUrl + apiName,
        };
        return this.ajax(options);
    
    }



    async post(apiName,body){
        const options = {
            method: 'POST',     
            data: body,
            url: this.baseUrl + apiName,
        };
        return this.ajax(options);
        // if(this.token){
        //     options.headers = { 
        //         'Token' : this.token,
        //      }
        // }
        // return axios(options).then(result=>
        //     result.data
        //     ).catch(e =>{
        //     console.log(e);
        // })

    

    }

    async tryAutoLogin(){
        if(!this.token)
          this.token = localStorage.getItem("user_token");
        if(!this.token){
            return {success:false}
        }
        return this.post("checkToken",{token:this.token})
    }


    async login(username, password){

       return this.post("login",{username,password}).then(r=>{
        if(r.token){
            this.token = r.token;
            localStorage.setItem("user_token",r.token);
        }
        return r;
       });

    }

    async logout(){
        if(this.token){
            return this.post("logout",{}).then(r=>{
                this.token=null;
                localStorage.removeItem("user_token");
                return r;
            })

        }

    }

    async fetchCurrentExamInfo(){
        return this.post("exam/currentStatus");
        
    }

    async startNewExam(){
        return this.post("exam/startNewExam");
    }

    async submitAnswer(answer){
        return this.post("exam/submitAnswer",{answer});
    }

    async nextQuestion(){
        return this.post("exam/nextQuestion");
    }














};

const server = new ServerApi();
export default server;