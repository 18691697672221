import OneClickButton from '../utils/OneClickButton'

import React from 'react'

function StartView(props) {

    const {title, startExam} = props;

    return  (<div className="row  h-100 pt-5 justify-content-center">
        <div className="col-md-6 col-lg-4 p-5">
            <div className="text-center   h3 mb-3">{title}</div>

            <OneClickButton className="btn btn-primary w-100 p-3" onClick={startExam} text="开始" loadingText="正在载入..." />
        </div>

</div> );
}

export default StartView
