import React, { useState, useEffect } from 'react';
import server from '../../server/server'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {TimeUtil} from '../../utils/TimeUtil'


function formatSetp(step){


    switch (step){
        case "START": return "开始";
        case "END": return "已完成";
        case "ERRPR": 
        case "EXAM":
            return "尚未完成";

    
    }
    return "未知";

}

function ExamListView(props) {

    const [examList,setExamList] = useState(null);


    useEffect(()=>{

        server.post('userExam/listExam').then( r=>{
            r.sort((it1,it2)=> it2.dispatchTime-it1.dispatchTime )
            setExamList(r);
        } );

    },[]);


    if(examList == null){
        return <h1>Loading</h1>
    }

    const redoExam = (e)=>{
        server.post('userExam/redoExam',{examPaperId:e.examPaperId}).then(r =>{
            if(r.examPaperId && r.examPaperId > 0){
                props.history.push('/exam/'+ r.examPaperId);
            }
        })
    }


    const createRedoLink = (e)=>{
        return e.enableRedo ? (<a href="#" onClick={ ()=>redoExam(e)}>再做一次</a>) : null
    }
    

    const tableRows = examList.map(e => {

        let redoLink = null;


        let opEl = null;
        let statusEl = null;
        let className = "";
        if(e.step == "END"){
            let costTime = null;
            if(e.endTime !=0){
                costTime="[" + TimeUtil.formatDuration(e.endTime -e.startTime) + "]";
            }
            className = "text-success";
            statusEl = <span className={className}>已完成{costTime} </span>
            opEl = createRedoLink(e);
        }else if(! e.enable){
            className = "text-danger";
            statusEl = <span className={className}>已逾期 </span>;
            opEl = createRedoLink(e);
        }else{
            
            statusEl = <span className={className}>{e.step=="START" ? "未开始":"未完成"} </span>;
            opEl = <Link to={'/exam/' + e.examPaperId}>做作业</Link>
        }

        let endTime = TimeUtil.formatlogTime(e.finalTime);
        if(e.endTime && e.endTime > 0){
            endTime = TimeUtil.formatlogTime(e.endTime);
        }

        let title= e.title;
        if(e.redoTimes > 0){
            title = title + "[重做" + e.redoTimes + "]";
        }
            
        return (<tr key={e.examPaperId}>
         <td>{TimeUtil.formatlogTime(e.dispatchTime)}</td>
        <td>{title}</td>
      
        <td>{e.index}/{e.total}</td>
        <td>{ Math.floor(e.right*100/e.total)} %</td>
        <td><span className={className}>{endTime}</span></td>
        <td>{statusEl}</td>
        <td>{opEl}</td>
    </tr>)}
    );






    return (
        <div className="h-100 overflow-auto">
        <table className="table">
            <thead>
                <tr>
                <th scope="col">派发时间</th>
                <th scope="col">标题</th>
             
                <th scope="col">进度</th>
                <th scope="col">正确率</th>
                <th scope="col">截止/完成时间</th>
                <th scope="col">状态</th>
                <th scope="col">操作</th>
                </tr>
            </thead>   
            <tbody>
                {tableRows}
            </tbody>   

        </table>
        </div>
    )
}

export default ExamListView
