import React, { useState, useEffect,useRef } from 'react';
import OneClickButton from '../utils/OneClickButton'



function loadFile(fileName, content){
    var aLink = document.createElement('a');
    var blob = new Blob([content], {
        type: 'text/plain'
     });
    var evt = new Event('click');
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);
    aLink.click();
    URL.revokeObjectURL(blob);
}



function FillEmptyNomal(props) {
    const {question: {title,optionList}, submitAnswer} = props;

    const [userInput,setUserInput] = useState("");
    const [btnDisable,setBtnDisable] = useState(false);

    const inputEl = useRef(null);

    useEffect(() => {
        setBtnDisable(false);
        setUserInput("");
        inputEl.current.focus();
    }, [props])

    const onFormSubmit  = (e)=> {
        e.preventDefault();
       
        if(userInput === ""){
            return;
        }
    
        setBtnDisable(true);
        submitAnswer(userInput);
    }

    let downloadLink = null;
    if(optionList && optionList.length > 0){
        const dc = optionList.map(op=> op.text).join("\r\n")
        downloadLink = (       
             <div className="text-center">
            <a href="#" onClick={()=>loadFile("1.txt",dc)} >下载附件</a>
        </div>);
    }


    return (
        <div className="container-sm">
            <div className="row  h-100 align-items-center justify-content-center">
                <div className="col-10 ">
                <form onSubmit={onFormSubmit}>
                    <div className={"text-center  h4 py-4"}>
                        <span className="text-break">{title}</span>
                    </div>
                    {downloadLink}
                    <div className="text-center">
                        <input maxLength="200" type="text" className="fill-empty-inputbox" ref={inputEl} value={userInput} onChange={(e)=>setUserInput(e.target.value)} />
                    </div>
                    <div  className="text-center mt-4">
                        <button type="submit" disabled={btnDisable} className="btn btn-primary w-50 p-3"  >按回车提交</button>
                    </div>
                </form>
                </div>
              
            </div>
        </div>
    )
};

function FillEmptyError(props){
    const {answer,userAnswer,question: {title}, nextQuestion} = props;
    const [btnDisable,setBtnDisable] = useState(false);
    const btnEl = useRef(null);

    useEffect(() => {
        btnEl.current.focus();
      }, [])
    const onFormSubmit  = (e)=> {
        e.preventDefault();
    
        setBtnDisable(true);
        nextQuestion();
    }


    return (
        <div className="container-sm">
            <div className="row  h-100 align-items-center justify-content-center">
                <div className="col-10 ">
                <form onSubmit={onFormSubmit}>
                    <div className={"text-center  h4 py-4"}>
                        <span className="text-break">{title}</span>
                    </div>
                    <div className="text-center h5">
                        <span>正确答案:</span><span className="text-success text-break">{answer}</span>
                    </div>
                    <div className="text-center h5">
                        <span>你的答案:</span><span className="text-danger text-break">{userAnswer}</span>
                    </div>
                    <div  className="text-center mt-4">
                        <button type="submit"  disabled={btnDisable}   className="btn btn-danger w-50 p-3" ref={btnEl} >回答错误,下一题</button>
                    </div>
                </form>
                </div>
              
            </div>
        </div>
    )   
}



function FillEmpty(props){
    const {step} = props;

    if(step == "EXAM"){
        return <FillEmptyNomal {...props} />
    }else if(step == "ERROR"){
        return <FillEmptyError {...props} />
    }



    return <h1>Loading</h1>;
}

export default FillEmpty
