import React, { Component } from "react";
import server from '../../server/server'

class UserConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordConfig: "",
            tip:"",
            success:false
        };

        // this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange =(event)=> {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

    onFormSubmit  = (e)=> {
        e.preventDefault();
       
        if(this.state.newPassword.length> 20){
            this.setState({success:false,tip:"密码太长"})
            return;
        }
        if(this.state.newPassword.length < 3){
            this.setState({success:false,tip:"密码太短"})
            return;
        }

        if(this.state.newPassword != this.state.newPasswordConfig ){
            this.setState({success:false,tip:"新密码不一致"})
            return;
        }
        this.setState({success:true,tip:""});
        server.post("user/changePassword",{oldPassword:this.state.oldPassword, newPassword:this.state.newPassword}).then(r=>{
            if(r.success){
                this.setState({success:true,tip:"密码修改成功"});
            }else{
                this.setState({success:false,tip:"密码修改失败,请检查原密码"});
            }
        })

    
      
    }


    render() {
        const tip = (
            <div className={"text-center py-2 my2 " + (this.state.success ? "text-success" : "text-danger")}>
                {this.state.tip}
            </div>
        );

        return (
            <div className="row  h-100 pt-5 justify-content-center">
                <div className="col-md-6 col-lg-4 p-5">
                    <form onSubmit={this.onFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">旧密码</label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                name="oldPassword"
                                value={this.state.oldPassword}
                                onChange={this.handleInputChange} 
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputPassword2">新密码</label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword2"
                                name="newPassword"
                                value={this.state.newPassword}
                                onChange={this.handleInputChange} 
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputPassword3">新密码确认</label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword3"
                                name="newPasswordConfig"
                                onChange={this.handleInputChange} 
                                value={this.state.newPasswordConfig}
                            />
                        </div>
                        {tip}
                        <button type="submit" className="btn btn-primary">
                            提交
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default UserConfig;
