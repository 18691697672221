import { BrowserRouter, Switch, Route } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import server from './server/server'
import Login from './compoents/auth/Login'
import MainFrame from './compoents/layout/MainFrame'

function App() {

  const [isLogin,setIsLogin] = useState(false);
  const [autoLoginEnd,setAutoLoginEnd] = useState(false);
  const [userInfo,setUserInfo] = useState(null);

  useEffect( ()=>{
    server.tryAutoLogin().then( (result)=>{
      setAutoLoginEnd(true);
     

      if(result.success){
        setUserInfo({nickname:result.nickname,admin:result.admin});
      }
      setIsLogin(result.success);
    })
  },[]);

  if(!autoLoginEnd){
    return <h1>Loading</h1>
  }

  if(!isLogin){
    return <Login login={(username,password)=>{
      return server.login(username,password).then(r=>{
        if(r.success){
          
          setUserInfo({nickname:r.nickname,admin:r.admin})
          setIsLogin(true);
        }
        return r;
        
      });
    } } />

  }else{

    const logout =  ()=> {
      server.logout().then(()=> {
        setIsLogin(false);
        setUserInfo(null);
      });
    }

    return (
      <MainFrame userInfo={userInfo} logout ={logout} />
    );
  }



}

export default App;
