import React, { Component } from 'react'


class Login extends Component {
    state = { 
        username: '',
        password: '',
        authFail: false
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
      }
      handleSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password).then(r=>{
            if(!r.success){
                this.setState({authFail:true});
            }
        })
      }
    render() { 
      

        const errorTip = (  <div className="text-center py-2 my2 text-danger">
         { this.state.authFail ? "用户名或密码错误,请重试" :" "}
        </div>);

        return ( <div className="container-fluid h-100">
          <div className="row  h-100 align-items-center justify-content-center">
          <div className="col-sm-12 col-md-6 col-lg-4 p-5 bg-white shadow rounded">

            
            <form className="w-100" onSubmit={this.handleSubmit}>
                <div className="h3 text-center pb-4">
                  用户登录
                </div>
                <div className="border border-grey">
                  <input className="w-100 border-0 px-3 py-4 "  id='username' type="text"  placeholder="用户名" onChange={this.handleChange}/>
                </div>

                <div className="border border-grey">
                  <input className="w-100 border-0  px-3 py-4"  id='password' type="password" placeholder="密码" onChange={this.handleChange}/>
                </div>
                
                {
                  errorTip 
                }

                <div className="w-100 mt-3">
                    <button className="btn btn-primary w-100 rounded-0 p-2">登录</button>
                </div>
            </form>
            </div>
            </div>
        </div>  );
    }
}

export default Login;