
import React, { useState, useEffect } from "react";
const OneClickButton = (props) => {
    const [disable, setDisable] = useState(false);
    
    const {onClick,text,loadingText,...p} = props;
    
    const onClick2 = ()=>{
        setDisable(true);
        onClick();
    }


    return (<button disabled={disable} {...p} onClick={onClick2}>{disable ? (loadingText || text ): text}</button>);


}

export default OneClickButton;
