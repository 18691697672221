import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NavBar from './NavBar'
import ExamListView from '../exam/ExamListView'
import UserExam from '../exam/UserExam'
import UserConfig from '../user/UserConfig'
import DispatchExam from '../admin/DispatchExam'
import ExamProcessList from '../admin/ExamProcessList'



function MainFrame(props) {
    return (
        <BrowserRouter>
        <div className="d-flex flex-column p-0 h-100">
          <div className="w-100">
            <NavBar userInfo={props.userInfo} logout={props.logout} />
          </div>
          <div className="w-100 flex-grow-1 flex-shrink-1 overflow-hidden">
            <Switch>
              <Route exact path='/'component={ExamListView} />  
              <Route path='/exam/:examPaperId' component={UserExam} />  
              <Route exact path='/config'component={UserConfig} />  
              <Route exact path='/dispatchExam' component={DispatchExam} />  
              <Route exact path='/examProcessList' component={ExamProcessList} /> 
            </Switch>
          </div>
         
  
        </div>
      
      </BrowserRouter>
    )
}



export default MainFrame
