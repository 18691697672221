import React, { Component } from "react";
import server from '../../server/server'

class DispatchExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            script: "",
            param: "",
            title: "",
            questionCount: 20,
            timeLimit: 1440,
            seed:0,
            userGroupId:1,
            tip:"",
            success:false,
            scriptList:null,
            userGroupList:null,
            userList:null,
            userId:0,
            enableRedo:true

        };

        // this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentDidMount() {
        server.post("admin/listUserGroup",{}).then(list => this.setState({
            userGroupList:list,
            userGroupId:list[0].id

        }));
        server.post("admin/listScript",{}).then(list => this.setState({
            scriptList:list,
            script:list[0]
        
        }));

        server.post("admin/listUser",{}).then(list => {this.setState({
            userList:[{id:0,nickname:"分配给组"}, ...list  ]   

            });
       

        });
    }

    handleInputChange =(event)=> {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if(target.type=='numberbox'){
            value = parseInt(value);
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

    onFormSubmit  = (e)=> {
        e.preventDefault();

        const {script,param,title, questionCount,timeLimit,userGroupId,userId,enableRedo} = this.state;

        if(title == ""){
            this.setState({success:false,tip:"标题不能为空"})
            return;
        }

        if(questionCount < 1 || questionCount > 1000){
            this.setState({success:false,tip:"非法的题目数量"})
            return;
        }
       
        const postData={
            title,
            questionCount,
            param,
            script,
            seed:0,
            userGroupId:0,
            finalTime: new Date().getTime() + timeLimit *60000,
            enableRedo

        }

        const nUserId = parseInt(userId);
        if(nUserId == 0){
            postData.userGroupId = parseInt(userGroupId)
        }else{
            postData.userList = [ nUserId ];
        }


     
        this.setState({success:true,tip:""});
        server.post("admin/dispatchExam",postData).then(r=>{
            if(r.success){
                this.setState({success:true,tip:"题目分配成功"});
            }else{
                this.setState({success:false,tip:"操作失败"});
            }
        })

    
      
    }


    render() {

        const {script,param,title, questionCount, scriptList,userGroupList,timeLimit,userGroupId,userList,userId,enableRedo} = this.state;

        if(scriptList == null || userGroupList == null || userList==null) return <h1>Loading</h1>;


        const tip = (
            <div className={"text-center py-2 my2 " + (this.state.success ? "text-success" : "text-danger")}>
                {this.state.tip}
            </div>
        );

        return (
            <div className="row  h-100 pt-2 justify-content-center">
                <div className="col-8  p-5">
                    <form onSubmit={this.onFormSubmit}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">分配给用户</label>
                            <div className="col-sm-10">
                            <select  
                                className="form-control"
                                name="userId"
                                value={userId}
                                onChange={this.handleInputChange} 
                            >
                                {userList.map(s => {
                                    return <option key={s.id} value={s.id}>{s.nickname}</option>;
                                })}
                            </select>
                            </div> 
                           
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">分配给组</label>
                            <div className="col-sm-10">
                            <select  
                                disabled={ parseInt(userId) != 0}
                                className="form-control"
                                name="userGroupId"
                                value={userGroupId}
                                onChange={this.handleInputChange} 
                            >
                                {userGroupList.map(s => {
                                    return <option key={s.id} value={s.id}>{s.title}</option>;
                                })}
                            </select>
                            </div> 
                           
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">脚本</label>
                            <div className="col-sm-10">
                            <select  
                                className="form-control"
                                name="script"
                                value={script}
                                onChange={this.handleInputChange} 
                            >
                                {scriptList.map(s => {
                                    return <option key={s} value={s}>{s}</option>;
                                })}
                            </select>
                               
                           </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">脚本参数</label>
                            <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"                            
                                name="param"
                                value={param}
                                onChange={this.handleInputChange} 
                            />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">题目数量</label>
                            <div className="col-sm-10">
                            <input
                                type="numberbox"
                                className="form-control"                            
                                name="questionCount"
                                value={questionCount}
                                onChange={this.handleInputChange} 
                            /></div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">标题</label>
                            <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                onChange={this.handleInputChange} 
                                value={title}
                            /></div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">时限(分钟)</label>
                            <div className="col-sm-10">
                            <input
                                type="numberbox"
                                className="form-control"                            
                                name="timeLimit"
                                value={timeLimit}
                                onChange={this.handleInputChange} 
                            /></div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">允许重做</label>
                            <div className="col-sm-10">
                            <input
                                type="checkbox"
                                className="form-control form-check-input"                   
                                name="enableRedo"
                                checked={enableRedo}
                                onChange={this.handleInputChange} 
                            /></div>
                        </div>
                        {tip}
                        <button type="submit" className="btn btn-primary">
                            提交
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default DispatchExam;
