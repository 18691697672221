import React, { Component } from "react";
import server from '../../server/server'
import moment from 'moment'

import {TimeUtil} from '../../utils/TimeUtil'
class ExamProceeList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            tip:"",
            success:false,
            examProcessList:null,
            userList:null,
            itemList:null,
            filterNickname:"",
            filterTitle:"",
            filterStatus:"ALL",
            filterRightRateGreater:0,
            filterRightRateLess:100,
            filterDispatchMinutes:43200,
            sortRule:"DISPATCHTIME"



        };

        // this.handleInputChange = this.handleInputChange.bind(this);
    }


    tryCalcItemList(){
        const {userList,examProcessList} = this.state;
        if(userList == null || examProcessList == null) return;
        const itemList = examProcessList.map(p => ({...p}));
        const userDict={};
        userList.forEach(u => {
            userDict[u.id] = u;
        });
        itemList.forEach(item=>{
            item.nickname = userDict[item.userId].nickname;
            item.rightRate = Math.floor(item.right*100/item.total);
            item.newTitle = item.title;
            if(item.redoTimes>0){
                item.newTitle = item.newTitle + "[重做" + item.redoTimes  +  "]";
            }

        });
        itemList.sort((it1,it2)=> it2.dispatchTime-it1.dispatchTime );

        this.setState({itemList});

    }


    componentDidMount() {
        server.post("admin/listUser",{}).then(list => {this.setState({
            userList:list          
            });
            this.tryCalcItemList();

        });
        server.post("admin/listAllExamProcess",{}).then(list => {this.setState({
                examProcessList:list       
            });
            this.tryCalcItemList();
        });
    }
    handleInputChange =(event)=> {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if(target.type=='numberbox'){
            value = parseInt(value);
        }
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }


    createInputTextField(fieldLabel, fieldName,inputType){
        return  ( <div className="form-group row">
        <label className="col-4 col-form-label">{fieldLabel}</label>
        <div className="col-8">
            <input
                type={inputType === undefined ? "text" : inputType}
                className="form-control"                            
                name={fieldName}
                value={this.state[fieldName]}
                onChange={this.handleInputChange} 
            />
        </div>
     </div>);
    }

    createSelectField(fieldLabel, fieldName,options){
        return  ( <div className="form-group row">
        <label className="col-4 col-form-label">{fieldLabel}</label>
        <div className="col-8">
            <select           
                className="form-control"                            
                name={fieldName}
                value={this.state[fieldName]}
                onChange={this.handleInputChange} 
            >
                {
                    options.map(s=> <option key={s.value} value={s.value}>{s.title}</option>)
                }
            </select>
        </div>
     </div>);
    }

    createFilterForm(){
        const {filterNickname,filterTitle} = this.state;

        const  filterStatusOptions=[
            {value:"ALL",title:"全部"},
            {value:"END",title:"已完成"},
            {value:"EXAM",title:"尚未完成"},
            {value:"EXPIRED",title:"逾期"},
            {value:"NOTEND",title:"未完成"},
        ]

        const  sortOptions=[
            {value:"DISPATCHTIME",title:"派发时间"},
            {value:"USER",title:"用户"},
            {value:"TITLE",title:"标题"}
        
        ]

        return (<form className="p-3">
            {this.createInputTextField("用户过滤","filterNickname")}
            {this.createInputTextField("标题过滤","filterTitle")}
            {this.createSelectField("状态过滤","filterStatus",filterStatusOptions)}    
            {this.createInputTextField("正确大于","filterRightRateGreater","numberbox")}
            {this.createInputTextField("正确小于","filterRightRateLess","numberbox")}
            {this.createInputTextField("派发距今","filterDispatchMinutes","numberbox")}
            {this.createSelectField("排序规则","sortRule",sortOptions)}    
         
            

   </form>)



    }


    filterItems(){
        const {filterNickname,filterTitle,filterStatus,filterRightRateLess,filterRightRateGreater,filterDispatchMinutes,sortRule} = this.state;
        let itemList = [...this.state.itemList];

        if(filterNickname != ""){
            if(filterNickname.startsWith("!")){
                if(filterNickname.length > 1){
                    const excludeName = filterNickname.substring(1);
                    itemList = itemList.filter(i=> i.nickname.indexOf(excludeName) == -1);
                }
              

            }else{
              itemList = itemList.filter(i=> i.nickname.indexOf(filterNickname) >= 0);
            }
          
        }

        if(filterTitle != ""){
            if(filterTitle.startsWith("!") ){
                if(filterTitle.length > 1){
                    const excludeTitle = filterTitle.substring(1);
                    itemList = itemList.filter(i=> i.newTitle.indexOf(excludeTitle) == -1);
                }
            
            }else{
                itemList = itemList.filter(i=> i.newTitle.indexOf(filterTitle) >= 0);
            }
         
        }

        if(filterStatus != "ALL"){
            switch(filterStatus){
                case "END": 
                    itemList = itemList.filter(i=> i.step == "END"); break;
                case "EXAM":
                    itemList = itemList.filter(i=> i.enable && i.step != "END"); break;
                case "EXPIRED":
                    itemList = itemList.filter(i=> !i.enable && i.step != "END"); break;
                case "NOTEND":
                    itemList = itemList.filter(i=> i.step != "END"); break;
            }
        }
        if(filterRightRateLess < 100){
            itemList = itemList.filter(i=> i.rightRate <= filterRightRateLess); 
        }

        if(filterRightRateGreater > 0){
            itemList = itemList.filter(i=> i.rightRate >= filterRightRateGreater); 
        }

        if(filterDispatchMinutes < 999999){
            const checkTick = new Date().getTime() - filterDispatchMinutes* 60000;

            itemList = itemList.filter(i=> i.dispatchTime >= checkTick); 
        }

        if(sortRule == "DISPATCHTIME"){
            itemList.sort((it1,it2)=> it2.dispatchTime-it1.dispatchTime);

        }else if(sortRule == "USER"){
            itemList.sort((it1,it2)=> {
                if(it1.userId == it2.userId){

                    if(it1.title == it2.title){
                    return  it2.dispatchTime-it1.dispatchTime;
                    }else{
                        return it2.title > it1.title ? 1:-1;
                    }
                }
                return it2.userId - it1.userId;
               
            });

        }else if(sortRule == "TITLE"){
            itemList.sort((it1,it2)=> {
                if(it1.title == it2.title){
                    return  it2.dispatchTime-it1.dispatchTime;
                }
                return it2.title > it1.title ? 1:-1;
               
            }); 
        }

        return itemList;

    }

    deleteExamProcess(userId,examPaperId){
        server.post("admin/deleteExamProcess",{userId,examPaperId}).then(r=>{
            if(r.success){
                this.setState({ itemList: this.state.itemList.filter(i=> i.userId != userId || i.examPaperId != examPaperId)});
            }
        })
    }

    render() {
        let {itemList} = this.state;
        if(itemList == null) return <h1>Loading</h1>;

        


        const tableRows = this.filterItems().map(e => {

            let opEl = null;
            let className = "";
            if(e.step == "END"){
                let costTime = null;
                if(e.endTime !=0){
                    costTime="[" + TimeUtil.formatDuration(e.endTime -e.startTime) + "]";
                }
                className = "text-success";
                opEl = <span className={className}>已完成{costTime}</span>;
            }else if(! e.enable){
                className = "text-danger";
                opEl = <span className={className}>已逾期</span>;
            }else{
                opEl = <span className={className}>尚未完成</span>;
            }

            let endTime = TimeUtil.formatlogTime(e.finalTime);
            if(e.endTime && e.endTime > 0){
                endTime = TimeUtil.formatlogTime(e.endTime);
            }

                
            return (<tr key={"" + e.userId + "_" + e.examPaperId}>
                 <td>{e.nickname}</td>
             <td>{TimeUtil.formatlogTime(e.dispatchTime)}</td>
            <td>{e.newTitle}</td>
            
            <td>{e.index}/{e.total}</td>
            <td>{ e.rightRate} %</td>
            <td><span className={className}>{endTime}</span></td>
            <td>{opEl}</td>
            <td><a href="#" onClick={()=> this.deleteExamProcess(e.userId,e.examPaperId) } >删除</a></td>
        </tr>)}
        );


        return (<div className="row  h-100">
              <div className="col-3   h-100">

                {this.createFilterForm()}

              </div>
              <div className="col-9 overflow-auto  h-100">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">用户</th>
                        <th scope="col">派发时间</th>
                        <th scope="col">标题</th>                   
                        <th scope="col">进度</th>
                        <th scope="col">正确率</th>
                        <th scope="col">截止/完成时间</th>
                        <th scope="col">状态</th>
                        <th scope="col">删除</th>
                        </tr>
                    </thead>   
                    <tbody>
                        {tableRows}
                    </tbody>   

                </table>
              </div>

        </div>);
    }



}


export default ExamProceeList;
