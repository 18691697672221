import moment from "moment";

export const TimeUtil = {
    formatlogTime : (t) => {
        const nowt = new Date().getTime();
        //半年内的时间不显示年份
        let useShortTime = t + 180 * 86400 * 1000 > nowt;
        const formatString = useShortTime ? "MM-DD HH:mm" : "YYYY-MM-DD HH:mm";
        return moment(t).format(formatString);
    },

    formatDuration :  (ms) => {
        const mo = moment.duration(ms);
        let result = "";
        let a1 = [Math.floor(mo.asDays()),mo.hours(), mo.minutes(),mo.seconds()];
        let a2 = ["天","小时","分","秒"];
        for(let i=0;i<a1.length;i++) if(a1[i] > 0) result += a1[i] + a2[i];
        return result;
    
    }


}