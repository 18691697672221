import React from 'react';

import { Link } from 'react-router-dom'
const NavBar = (props) => {

    console.log(props);


    let dispatchExam = null;
  

    if(props.userInfo.admin){
      dispatchExam =  (<> <li className="nav-item active">
        <Link to='/dispatchExam' className="nav-link" href="#">分配作业</Link>
      </li>
      <li className="nav-item active">
        <Link to='/examProcessList' className="nav-link" href="#">作业统计</Link>
      </li>
      </>)
    }

  return (<nav className="navbar navbar-expand-sm navbar-dark bg-dark">
  <a className="navbar-brand" href="#">作业系统</a>
 
  <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="navbar-collapse collapse " id="navbarsExample03" >
  <div className="ml-auto text-white pr-5">你好,{props.userInfo.nickname}</div>
    <ul className="navbar-nav  pr-5">
      <li className="nav-item active">
        <Link to='/' className="nav-link" href="#">我的作业</Link>
      </li>

      <li className="nav-item active">
        <Link to='/config' className="nav-link" href="#">修改密码</Link>
      </li>

{dispatchExam}
      <li className="nav-item">
        <a className="nav-link" href="#" onClick={()=> props.logout()}>登出</a>
      </li>

    </ul>

  </div>
</nav>)


    
    
}

export default NavBar;